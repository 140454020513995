import React from 'react'
import Nav from 'react-bootstrap/Nav';

import './style.css';

function FooterLayout() {
  return (
    <div className="row">
        <Nav.Link className="col text-center align-item-center footer-label" href="/datenschutz" >
            <p className="footer-text">Datenschutz</p>
        </Nav.Link>
        <Nav.Link className="col text-center align-item-center footer-label" href="/impressum" >
            <p className="footer-text">Impressum</p>
        </Nav.Link>
    </div>
  )
}

export default FooterLayout