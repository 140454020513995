import React from 'react'
import { Navbar, Nav } from 'react-bootstrap';
import NavLink from './NavLink';

import './style.css'

function NavbarLayout(props) {
    const pathList = props.pathsList;
    const homePath = props.homePath;

    function isActive(item_href) {
        if (item_href === window.location.pathname) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <Nav justify defaultActiveKey={homePath.item_href}>
            <Nav.Item key={homePath.key_navItem} className="col-4">
                <Navbar.Brand className={`d-flex align-items-center h-100 w-100 p-1 justify-content-center`} href={homePath.item_href}>
                    <img id='img_logo_nav' src='/img/logo_navbar.jpg' alt="logo_nav"/>
                </Navbar.Brand>
            </Nav.Item>
            {pathList.map(item => {
                return (
                    <Nav.Item className="col-4" key={item.key_navItem}>
                        <NavLink isActive={isActive(item.item_href)} item={item} />
                    </Nav.Item>
                )
            })
            }

        </Nav>
    )
}

NavbarLayout.propTypes = {}

export default NavbarLayout
