//bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';
// react component
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// contents / PAGE
import Home from './pages/Home';
import UnsereDienste from './pages/UnsereDienste';
import UeberUns from './pages/UeberUns';
import Kontakt from './pages/Kontakt';
import NotFound from './pages/NotFound';
import Datenschutz from "./pages/Datenschutz";
import Impressum from "./pages/Impressum";
import NavbarLayout from './content/NavbarLayout/NavbarLayout';
import FooterLayout from './content/FooterLayout/FooterLayout';

// style customs
import './style/layout.css';

//Variable und Konfigs
const homePath = {
  key: 1,
  key_navLink: 101,
  key_navItem: 111,
  item: <Home />,
  name: 'Home',
  item_href: "/"
};

const pathWNavLink = [
  {
    key: 2,
    key_navLink: 102,
    key_navItem: 112,
    name: 'Unsere Dienste',
    item: <UnsereDienste />,
    item_href: "/unsere-dienste"
  },
  {
    key: 3,
    key_navLink: 103,
    key_navItem: 113,
    name: 'Ueber Uns',
    item: <UeberUns />,
    item_href: "/ueber-uns"
  },
  {
    key: 4,
    key_navLink: 104,
    key_navItem: 114,
    name: 'Kontakt',
    item: <Kontakt />,
    item_href: "/kontakt"
  },
];

const pathOthers = [
  {
    key: 110,
    item: <NotFound />,
    item_href: "/*"
  },
  {
    key: 120,
    item: <Datenschutz />,
    item_href: "/datenschutz"
  },
  {
    key: 130,
    item: <Impressum />,
    item_href: "/impressum"
  }
]

ReactDOM.createRoot(document.getElementById('root')).render(
  <div className="app">
    <BrowserRouter basename="/">
      <NavbarLayout className="top" pathsList={pathWNavLink} homePath={homePath} />
      <div className="center" id="scrollbar">
        <Routes>
          <Route key={homePath.key} path={homePath.item_href} element={homePath.item} />

          {pathWNavLink.map(item => {
            return (<Route key={item.key} path={item.item_href} element={item.item} />);
          })}

          {pathOthers.map(item => {
            return (<Route key={item.key} path={item.item_href} element={item.item} />);
          })}

        </Routes>
        <div id="footer" className='bottom footer w-100'>
          <FooterLayout></FooterLayout>
        </div>
      </div>
    </BrowserRouter>


  </div >
);

