import React from 'react'

function Impressum() {
  return (
    <div>
      <h1 className='fs-4 text-center'>Impressum</h1>
      <p className='fs-6 text-center'>Aktuell in bearbeitung.
        </p>
      </div>
  )
}

export default Impressum