import React from "react";

function Datenschutz() {
  return (
    <div className="container-fluid mt-3">
      <h1 className="fs-2 pb-2">Datenschutzerklärung</h1>
      <p className="fs-5">Allgemeine Hinweise</p>
      <p className="fs-6">
        Diese Datenschutzerklärung enthält ausführliche Informationen darüber,
        was mit Ihren persönlichen Daten passiert, wenn Sie unsere Website
        hek-technology.de besuchen. Personenbezogene Daten sind alle Daten, mit
        denen Sie sich persönlich identifizieren können. Wir halten uns bei der
        Verarbeitung Ihrer Daten streng an die gesetzlichen Bestimmungen,
        insbesondere die Datenschutzgrundverordnung („DSGVO“), und legen großen
        Wert darauf, dass Ihr Besuch auf unserer Website absolut sicher ist.
      </p>
      <p className="fs-5">Verantwortliche Stelle</p>
      <p className="fs-6">
        Datenschutzrechtlich verantwortlich für die Erhebung und Verarbeitung
        von personenbezogenen Daten auf dieser Website ist:
      </p>
      <div className="container w-75">
        <p className="fs-6">Vorname, Name: Halil Kocak</p>
        <p className="fs-6">Straße, Hausnummer: Im Steckenäckerle 23</p>
        <p className="fs-6">Postleitzahl, Ort: 75365 Calw</p>
        <p className="fs-6">Land: Deutschland</p>
        <p className="fs-6">E-Mail: halil.kocak@hek-technology.de</p>
      </div>
      <p className="fs-5 pt-2">Kontaktformular</p>
      <p className="fs-6">
        Sofern Sie mit uns Kontakt per E-Mail oder über ein Kontaktformular
        aufnehmen, werden übermittelte Daten einschließlich Ihrer Kontaktdaten
        gespeichert, um Ihre Anfrage bearbeiten zu können oder um für
        Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne
        Ihre Einwilligung nicht statt.
      </p>
      <p className="fs-6">
        Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
        ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
        DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit
        möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail.
        Die Rechtmäßigkeit der bis zum Widerruf erfolgten
        Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
      </p>
      <p className="fs-6">
        Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie
        uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht.
        Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen -
        bleiben unberührt.
      </p>
      <p className="fs-5 pt-2">Datenverwendung und -weitergabe</p>
      <p className="fs-6">
        Die personenbezogenen Daten, die Sie uns z.B. per E-Mail mitteilen (z.B.
        Ihr Name und Ihre Adresse oder Ihre E-Mail-Adresse), werden wir weder an
        Dritte verkaufen noch anderweitig vermarkten. Ihre personenbezogenen
        Daten werden nur zur Korrespondenz mit Ihnen und nur für den Zweck
        verarbeitet, zu dem Sie uns die Daten zur Verfügung gestellt haben. Zur
        Abwicklung von Zahlungen geben wir Ihre Zahlungsdaten an das mit der
        Zahlung beauftragte Kreditinstitut weiter.
      </p>
      <p className="fs-6">
        Die Verwendung von Daten, die bei Ihrem Besuch unserer Website
        automatisch erhoben werden, erfolgt nur zu den vorstehend genannten
        Zwecken. Eine anderweitige Verwendung der Daten findet nicht statt.
      </p>
      <p className="fs-6">
        Wir versichern, dass wir Ihre personenbezogenen Daten im Übrigen nicht
        an Dritte weitergeben, es sei denn, dass wir dazu gesetzlich
        verpflichtet wären oder Sie uns vorher Ihre Zustimmung gegeben haben.
      </p>
      <p className="fs-5 pt-2">SSL- bzw. TLS-Verschlüsselung</p>
      <p className="fs-6">
        Unsere Website benutzt aus Sicherheitsgründen und zum Schutz der
        Übertragung vertraulicher Inhalte, wie zum Beispiel Anfragen, die Sie an
        uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine
        verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
        Browsers von „http://” auf „https://” wechselt und an dem Schloss-Symbol
        in Ihrer Browserzeile.
      </p>
      <p className="fs-6">
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
        die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <p className="fs-5">§1 Speicherdauer</p>
      <p className="fs-6">
        Personenbezogene Daten, die uns über unsere Website mitgeteilt worden
        sind, werden nur so lange gespeichert, bis der Zweck erfüllt ist, zu dem
        sie uns anvertraut wurden. Soweit handels- und steuerrechtliche
        Aufbewahrungsfristen zu beachten sind, kann die Speicherdauer zu
        bestimmten Daten bis zu 10 Jahre betragen.
      </p>
      <p className="fs-5">§2 Betroffenenrechte</p>
      <p className="fs-6">
        Hinsichtlich der Sie betreffenden personenbezogenen Daten haben als
        Betroffener der Datenverarbeitung nach Maßgabe der gesetzlichen
        Bestimmungen folgende Rechte gegenüber dem Verantwortlichen:
      </p>
      <p className="fs-5">2.1 Widerrufsrecht</p>
      <p className="fs-6">
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sofern die Verarbeitung Ihrer Daten auf Ihrer
        Einwilligung beruht, haben Sie das Recht, eine einmal erteilte
        Einwilligung in die Verarbeitung von Daten gemäß Art. 7 Abs. 3 DSGVO
        jederzeit mit Wirkung für die Zukunft zu widerrufen. Durch den Widerruf
        der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung
        bis zum Widerruf erfolgten Verarbeitung nicht berührt. Speicherung der
        Daten für Abrechnungs- und buchhalterische Zwecke bleibt von einem
        Widerruf nicht berührt.
      </p>
      <p className="fs-5">2.2 Auskunftsrecht</p>
      <p className="fs-6">
        Sie haben das Recht, gemäß Art. 15 DSGVO von uns eine Bestätigung
        darüber zu verlangen, ob wir personenbezogene Daten verarbeiten, die Sie
        betreffen. Liegt eine solche Verarbeitung vor, haben Sie das Recht auf
        Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten, die
        Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen
        Daten, die Empfänger oder Kategorien von Empfängern, gegenüber denen
        Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer
        bzw. die Kriterien für die Festlegung der Speicherdauer, das Bestehen
        eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung,
        Widerspruch gegen die Verarbeitung, Beschwerde bei einer
        Aufsichtsbehörde, die Herkunft Ihrer Daten, wenn diese nicht durch uns
        bei Ihnen erhoben wurden, das Bestehen einer automatisierten
        Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftige
        Informationen über die involvierte Logik und die Sie betreffende
        Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung,
        sowie Ihr Recht auf Unterrichtung, welche Garantien gemäß Art. 46 DSGVO
        bei Weiterleitung Ihrer Daten in Drittländer bestehen.
      </p>
      <p className="fs-5">2.3 Recht auf Berichtigung</p>
      <p className="fs-6">
        Sie haben das Recht, gemäß Art. 16 DSGVO jederzeit unverzügliche
        Berichtigung Sie betreffender unrichtiger personenbezogener Daten
        und/oder die Vervollständigung Ihrer unvollständigen Daten zu verlangen.
      </p>
      <p className="fs-5">2.4 Recht auf Löschung</p>
      <p className="fs-6">
        Sie haben das Recht, gemäß Art. 17 DSGVO die Löschung Ihrer
        personenbezogenen Daten zu verlangen, sofern einer der folgenden Gründe
        zutrifft:
      </p>
      <div className="container">
        <p className="fs-6">
          a) Ihre personenbezogenen Daten sind für die Zwecke, für die sie erhoben
          oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
        </p>
        <p className="fs-6">
          b) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gemäß
          Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es
          fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
        </p>
        <p className="fs-6">
          c) Sie legen gemäß Art. 21 Abs. 1 DSGVO Widerspruch gegen die
          Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für
          die Verarbeitung vor, oder Sie legen gemäß Art. 21 Abs. 2 DSGVO
          Widerspruch gegen die Verarbeitung ein.
        </p>
        <p className="fs-6">
          d) Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.
        </p>
        <p className="fs-6">
          e) Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
          rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht des
          Mitgliedstaates erforderlich, dem wir unterliegen.
        </p>
        <p className="fs-6">
          f) Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
          Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
        </p>
      </div>
      <p className="fs-6">
        Dieses Recht besteht jedoch nicht, soweit die Verarbeitung erforderlich
        ist:
      </p>
      <div className="container">
        <p className="fs-6">
          a) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
        </p>
        <p className="fs-6">
          b) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung
          nach dem Recht der Union oder des Mitgliedstaates, dem wir
          unterliegen, erfordert, oder zur Wahrnehmung einer Aufgabe, die im
          öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt
          erfolgt, die uns übertragen wurde;
        </p>
        <p className="fs-6">
          c) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen
          Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;
        </p>
        <p className="fs-6">
          d) für im öffentlichen Interesse liegende Archivzwecke,
          wissenschaftliche oder historische Forschungszwecke oder für
          statistische Zwecke gemäß Art. 89 Abs. 1 DSGVO, soweit das
          Betroffenenrecht voraussichtlich die Verwirklichung der Ziele dieser
          Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder
        </p>
        <p className="fs-6">
          e) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
        </p>
      </div>
      <p className="fs-6">
        Haben wir Ihre personenbezogenen Daten öffentlich gemacht und sind wir
        nach Vorstehendem zu deren Löschung verpflichtet, so treffen wir unter
        Berücksichtigung der verfügbaren Technologie und der
        Implementierungskosten angemessene Maßnahmen, auch technischer Art, um
        für die Datenverarbeitung Verantwortliche, die die personenbezogenen
        Daten verarbeiten, darüber zu informieren, dass Sie als betroffene
        Person von ihnen die Löschung aller Links zu Ihren personenbezogenen
        Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten
        verlangt haben.
      </p>
      <p className="fs-5">2.5 Recht auf Einschränkung der Verarbeitung</p>
      <p className="fs-6">
        Sie haben das Recht, gemäß Art. 18 DSGVO die Einschränkung der
        Verarbeitung (Sperrung) Ihrer personenbezogenen Daten zu verlangen.
        Hierzu können Sie sich jederzeit unter der im Impressum angegebenen
        Adresse an uns wenden. Das Recht auf Einschränkung der Verarbeitung
        besteht in folgenden Fällen:
      </p>
      <div className="container">
        <p className="fs-6">
          a) Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
          Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
          überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
          Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </p>
        <p className="fs-6">
          b) Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
          geschah / geschieht, können Sie statt der Löschung die Einschränkung der
          Datenverarbeitung verlangen.
        </p>
        <p className="fs-6">
          c) Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
          jedoch zur Ausübung, Verteidigung oder Geltendmachung von
          Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die
          Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </p>
        <p className="fs-6">
          d) Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
          muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
          werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
          haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </p>
      </div>
      <p className="fs-6">
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
        haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
        Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
        von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
        natürlichen oder juristischen Person oder aus Gründen eines wichtigen
        öffentlichen Interesses der EU oder eines Mitgliedstaats verarbeitet
        werden.
      </p>
      <p className="fs-5">2.6 Recht auf Unterrichtung</p>
      <p className="fs-6">
        Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der
        Verarbeitung gegenüber uns geltend gemacht, sind wir verpflichtet, allen
        Empfängern, denen Ihre personenbezogenen Daten offengelegt wurden, diese
        Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung
        mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit
        einem unverhältnismäßigen Aufwand verbunden. Ihnen steht gemäß Art. 19
        DSGVO das Recht zu, nach Verlangen über diese Empfänger unterrichtet zu
        werden.
      </p>
      <p className="fs-5">2.7 Recht, nicht einer ausschließlich auf einer automatisierten
      Verarbeitung – einschließlich Profiling – beruhenden Entscheidung
      unterworfen zu werden</p>
      <p className="fs-6">
        Sie haben das Recht, gemäß Art. 22 DSGVO nicht einer ausschließlich auf
        einer automatisierten Verarbeitung – einschließlich Profiling –
        beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber
        rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
        beeinträchtigt.
      </p>
      <p className="fs-6">Dies gilt nicht, wenn die Entscheidung</p>
      <div className="container">
        <p className="fs-6">
          a) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und
          uns erforderlich ist,
        </p>
        <p className="fs-6">
          b) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten,
          denen der Verantwortliche unterliegt, zulässig ist und diese
          Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und
          Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
        </p>
        <p className="fs-6">mit Ihrer ausdrücklichen Einwilligung erfolgt.</p>
        <p className="fs-6">
          c) Allerdings dürfen die Entscheidungen in den in (a) bis (c) genannten
          Fällen nicht auf besonderen Kategorien personenbezogener Daten nach Art.
          9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder lit. g
          gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten
          sowie Ihrer berechtigten Interessen getroffen wurden.
        </p>
      </div>
      <p className="fs-6">
        In den in (a) und (c) genannten Fällen treffen wir angemessene
        Maßnahmen, um Ihre Rechte und Freiheiten sowie Ihre berechtigten
        Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des
        Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des
        eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
      </p>
      <p className="fs-5">2.8 Recht auf Datenübertragbarkeit</p>
      <p className="fs-6">
        Sofern die Verarbeitung auf Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a
        DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gemäß Art.
        6 Abs. 1 lit. b DSGVO beruht und mithilfe automatisierter Verfahren
        erfolgt, haben Sie das Recht, gemäß Art. 20 DSGVO Ihre personenbezogenen
        Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
        gängigen und maschinenlesebaren Format zu erhalten und einem anderen
        Verantwortlichen zu übermitteln oder die Übermittlung an einen anderen
        Verantwortlichen zu verlangen, soweit dies technisch machbar ist.
      </p>
      <p className="fs-5">2.9 Widerspruchsrecht</p>
      <p className="fs-6">
        Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die
        Interessenabwägung nach Art. 6 Abs. 1 lit. f DSGVO stützen, haben Sie
        jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen
        Situation ergeben, gegen die Verarbeitung Ihrer personenbezogenen Daten
        Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung
        gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf der eine
        Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung. Wenn Sie
        Widerspruch einlegen, werden wir Ihre betroffenen personenbezogenen
        Daten nicht mehr verarbeiten, es sei denn, wir können zwingende
        schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
        Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient
        der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
        (Widerspruch nach Art. 21 Abs. 1 DSGVO).
      </p>
      <p className="fs-6">
        Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu
        betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die
        Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
        derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit
        es mit solcher Direktwerbung in Verbindung steht. Wenn Sie
        widersprechen, werden Ihre personenbezogenen Daten anschließend nicht
        mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21
        Abs. 2 DSGVO).
      </p>
      <p className="fs-6">
        Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten
        der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG -
        Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei
        denen technische Spezifikationen verwendet werden.
      </p>
      <p className="fs-5">2.10 Beschwerderecht bei der zuständigen Aufsichtsbehörde gemäß Art. 77
      DSGVO</p>
      <p className="fs-6">
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
        Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
        Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
        des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
        Rechtsbehelfe.
      </p>
      <p className="fs-6">Die für uns zuständige Aufsichtsbehörde ist:</p>
      <p className="fs-6">
        Der Landesbeauftragte für den Datenschutz und die Informationsfreiheit
        Baden-Württemberg Postfach 10 29 3270025 Stuttgart oder:
        Lautenschlagerstraße 2070173 Stuttgart Telefon: 07 11/61 55 41-0 E-Mail:
        poststelle@lfdi.bwl.de Internet:
        https://www.baden-wuerttemberg.datenschutz.de
      </p>
      <p className="fs-6">
        Gültigkeit und Änderung dieser Datenschutzerklärung
      </p>
      <p className="fs-6">
        Diese Datenschutzerklärung gilt ab dem 01. September 2024. Wir behalten
        uns das Recht vor, diese Datenschutzerklärung jederzeit unter Beachtung
        der geltenden Datenschutzvorschriften zu ändern. Dies kann z.B. zur
        Einhaltung neuer Gesetzesbestimmungen oder zur Berücksichtigung der
        Änderungen unserer Website bzw. neuer Dienstleistungen auf unserer
        Website erforderlich sein. Es gilt die zum Zeitpunkt Ihres Besuchs
        abrufbare Fassung.
      </p>
      <p className="fs-6">
        Sollte diese Datenschutzerklärung geändert werden, beabsichtigen wir,
        Änderungen an unserer Datenschutzerklärung auf dieser Seite bekannt zu
        geben, sodass Sie umfassend darüber informiert sind, welche
        personenbezogene Daten wir sammeln, wie wir sie verarbeiten und unter
        welchen Umständen sie weitergegeben werden können.
      </p>
    </div>
  );
}

export default Datenschutz;
