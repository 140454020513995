import Nav from 'react-bootstrap/Nav';


function NavLink(props) {

    const item = props.item;
    const isActive = props.isActive;

    return (
        <Nav.Link className={`${isActive ? "isActive" : ""} d-flex align-items-center nav-text`} href={item.item_href} >
            <p className="fs-4">{item.name}</p>
        </Nav.Link>
    )
}

export default NavLink