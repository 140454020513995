import './customStyle.css';

//import data from '../content/content_Data/home_data.json'

import CustomSlider from '../content/custom_slider/CustomSlider';

export default function Home() {

  const slide_elements = [
    {
      index: 1,
      element: (
        <>
          <div className='logo-wrapper col-4'>
            <img src='/img/logo.jpg' alt='logo' id='home-logo' />
          </div>
          <div className='home-text col-6 p-2'>
            <p className='fs-4 cursorD txt-jtfy'>
              IT Beratung für Kleinunternehmen die Wachsen wollen. Sie wollen ihre Social Media Seiten Profesionel gestalten und Redaktuell arbeiten? Die Gestaltung ihre IT Medien und Kommunikationen brauchen eine Optimierung? Wir unterstützen Sie!
            </p>
          </div>
        </>
      )
    }, 
    {
      index: 2,
      element: (
        <div className='row h-100 w-100'>
          <div className='row h-100 w-100 align-items-center'>
              <div className='h-25'>
                <p className='fs-4 text-center cursorD'>
                  Wartungsarbeiten
                </p>
                <p className='fs-6 cursorD'>
                  Aktuell wird die Seite bearbeitet und neu erstellt. Daher bitten wir um Vertändnis!
                </p>
              </div>
          </div>
        </div>
      )
    }
  ]

  return (
    <div className="content row h-100 w-100">
      <CustomSlider props={slide_elements} />
    </div>
  )
}


