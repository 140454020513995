import React from 'react'

function NotFound() {
    return (
        <div className="body w-100 h-100">
        <p className="fs-5 text-center cursorD">404 - Not Found</p>
        </div>
    )
}

export default NotFound