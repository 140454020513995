import React, { useState } from 'react';

export default function CustomSlider(props) {
    // State to keep track of the active element
    const [activeElement, setActiveElement] = useState(1);
    const [prevScrollY, setPrevScrollY] = useState(0);
    const slide_elements = props.props;
    
    
    const handleWheel = (event) => {
        const deltaY = event.deltaY;
        // deltaY > 0 indicates scrolling down, and deltaY < 0 indicates scrolling up
        if (deltaY > 0) {
            if (activeElement < (slide_elements.length)) {
                setActiveElement((activeElement + 1));
            } else {
                setActiveElement(1);
            }
        } else if (deltaY < 0) {
            if (activeElement < (slide_elements.length)) {
                const temp_active_element = (activeElement - 1)
                if(temp_active_element > 0 && temp_active_element < (slide_elements.length)){
                    setActiveElement(temp_active_element);
                }
            } else {
                setActiveElement(slide_elements.length);
            }
        }
    };

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        // Compare currentScrollY with prevScrollY to determine scroll direction
        if (currentScrollY > prevScrollY) {
            if (activeElement < (slide_elements.length)) {
                setActiveElement((activeElement + 1));
            } else {
                setActiveElement(1);
            }
        } else if (currentScrollY < prevScrollY) {
            if (activeElement < (slide_elements.length)) {
                const temp_active_element = (activeElement - 1)
                if(temp_active_element > 0 && temp_active_element < (slide_elements.length)){
                    setActiveElement(temp_active_element);
                }
            } else {
                setActiveElement(slide_elements.length);
            }
        }
        // Update prevScrollY with the current scroll position
        setPrevScrollY(currentScrollY);
    };


    return (<>
        <div className="col slider_body">
            <div className="slider_wrapper" onWheel={handleWheel} onScroll={handleScroll}>
                {
                    slide_elements.map((element) => {
                        return (
                            <div
                                key={element.index}
                                className={activeElement === element.index ? 'slide_active' : 'slide_inactive'}
                            >
                                <div className='row h-100 w-100'>
                                    <div className='row h-100 w-100 justify-content-center'>
                                        {element.element}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        <div className="col-1 align-self-center slide_scroll_points">
            <div className='slide_bullets_wrapper '>
                {
                    slide_elements.map((element) => {
                        if (element.index === activeElement) {
                            return (
                                <span onClick={handleScroll} key={element.index + 10} className='slide_point active'></span>
                            )
                        } else {
                            return (
                                <span onClick={handleScroll} key={element.index + 10} className='slide_point' ></span>
                            )
                        }
                    })
                }
            </div>
        </div>
    </>
    )
}